"use strict";

// tohle nahraje i soubory, ktere nejsou primo linkovane z entrypointu
import "./img/*.{png,jpg,jpeg,gif,svg}";

// import stylu
import "../index.scss";

/******* SLIDERS */
import Glide from "@glidejs/glide";
// Najde všechny elementy s třídou 'glide'
const glideElements = document.querySelectorAll(".glide");
// Vytvoří pole ID z těchto elementů
const sliders = Array.from(glideElements).map((element) => element.id);
// Přidejte další ID, pokud máte více sliderů
window.addEventListener("DOMContentLoaded", () => {
  sliders.forEach((sliderId) => {
    new Glide(`#${sliderId}`, {
      type: "carousel",
      startAt: 0,
      perView: 1,
      focusAt: "center",
      gap: 0,
    }).mount();
  });
});
/******* SLIDERS  */

/******* lightbox */
import GLightbox from "glightbox";
const lightbox = GLightbox();

/******* paralax */
import "intersection-observer";

const paralaxObserver = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add(entry.target.dataset.paralax);
      paralaxObserver.unobserve(entry.target);
    }
  });
});

document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll(".paraX").forEach((elm) => {
    paralaxObserver.observe(elm);
  });
});

// SmoothScroll inicializace
import SmoothScroll from "smooth-scroll";
new SmoothScroll('a[href*="#"]', {
  header: "header",
  speed: 300,
  speedAsDuration: true,
});

// NodeList.prototype.forEach() polyfill
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

// clickToggleClass - BEGIN
window.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll("*[data-click-toggle-class]").forEach((elm) => {
    elm.addEventListener("click", (event) => {
      event.preventDefault();

      const classes = event.currentTarget.dataset.clickToggleClass.split("|");
      const targets = event.currentTarget.dataset.clickToggleTarget
        ? [...document.querySelectorAll(event.target.dataset.clickToggleTarget)]
        : [event.target];
      const toggledClases = targets[0].classList.contains(classes[0])
        ? {
            remove: classes[0],
            add: classes[1],
          }
        : {
            remove: classes[1],
            add: classes[0],
          };
      targets.forEach((elm) => {
        elm.classList.remove(toggledClases.remove);
        elm.classList.add(toggledClases.add);
      });
    });
  });
});
// clickToggleClass - END

/* LANG SWICHER */
// Funkce pro zobrazení/skrytí menu
function toggleMenu() {
  const menu = document.getElementById("header_barLangWrap");
  menu.style.display = menu.style.display === "block" ? "none" : "block";
}

// Otevření menu při kliknutí
document.getElementById("header_barLang").addEventListener("click", toggleMenu);

// Zobrazení menu při najetí myší
document
  .getElementById("header_barLang")
  .addEventListener("mouseenter", function () {
    document.getElementById("header_barLangWrap").style.display = "block";
  });

// Skrytí menu při odjetí myší
document
  .getElementById("header_barLangWrap")
  .addEventListener("mouseleave", function () {
    document.getElementById("header_barLangWrap").style.display = "none";
  });

// Skrytí menu při opakovaném kliknutí
document
  .getElementById("header_barLangWrap")
  .addEventListener("click", toggleMenu);
/* LANG SWICHER KONEC */

/* MENU START */
const openMenu = document.querySelector(".menu-open");
const closeMenu = document.querySelector(".menu-close");
const menuDiv = document.querySelector(".ozmenu");
const menu = document.querySelector(".ozmenu-nav");
const dropDowns = menu.getElementsByClassName("nav-dropdown");
const dropDownsChild = menu.querySelectorAll(".dropdown .nav-dropdown");

openMenu.addEventListener("click", menuToggle);
closeMenu.addEventListener("click", menuToggle);

document.body.insertAdjacentHTML("beforeend", "<div id='menu-overlay'></div>");
document.querySelector("#menu-overlay").addEventListener("click", menuToggle);

function menuToggle() {
  menuDiv.classList.toggle("active");
  document.body.classList.toggle("hide-scrolling");
  document.body.classList.toggle("mobile-menu-active");
  document.getElementById("menu-overlay").classList.toggle("show");
}
let i = 0;
for (i = 0; i < dropDownsChild.length; i++) {
  dropDownsChild[i].classList.add("child");
  dropDownsChild[i].addEventListener("click", function () {
    this.classList.toggle("opened");
  });
}
for (i = 0; i < dropDowns.length; i++) {
  if (!dropDowns[i].classList.contains("child")) {
    dropDowns[i].classList.add("parent");
    dropDowns[i].addEventListener("click", function () {
      this.classList.toggle("opened");
    });
  }
}

/* MENU END */
