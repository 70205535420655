"use strict";

// tohle nahraje i soubory, ktere nejsou primo linkovane z entrypointu
import "./img/*.{png,jpg,jpeg,gif,svg}";

// import stylu
import "../index.scss";

/******* SLIDERS */
import Glide from "@glidejs/glide";
// Najde všechny elementy s třídou 'glide'
const glideElements = document.querySelectorAll(".glide");
// Vytvoří pole ID z těchto elementů

// Přidejte další ID, pokud máte více sliderů
window.addEventListener("DOMContentLoaded", () => {
  //sliders.forEach((sliderId) => {
  const glideHP = new Glide(`#glide`, {
    type: "slider",
    startAt: 0,
    perView: 1,
    focusAt: "center",
    rewind: false,
    gap: 0,
  }).mount();
  //});
  /*
  const HPslideGo1 = document.querySelector("#HPslideGo1");
  HPslideGo1.addEventListener("click", (event) => {
    console.log("HPslideGo1");
    glideHP.go("=2");
  });
  */
  const glideHP2 = document.querySelector("#glide");
  glideHP2.addEventListener(
    "wheel",
    throttle((e) => {
      e.stopPropagation();

      if (e.deltaY < -5) {
        // up
        glideHP.go("<");
      }
      if (e.deltaY > 5) {
        // down
        glideHP.go(">");
      }
      return false;
    }, 50),
    { passive: true }
  );

  const links = document.querySelectorAll("a.hp__slideLink");
    
    links.forEach(link => {
        link.addEventListener("click", function (event) {
            event.preventDefault(); // Zabrání výchozímu chování odkazu
            
            const index = link.getAttribute("data-index"); // Načte hodnotu data-index
            
            if (index !== null) {
                glideHP.go(`=${index}`); // Zavolá funkci s příslušným indexem
            }
        });
    });

  document.querySelectorAll('.glide_nextBtn').forEach(button => {
    button.addEventListener('click', () => {
        glideHP.go(">");
    });
  });
  document.querySelectorAll('.glide_nextBtnZ').forEach(button => {
    button.addEventListener('click', () => {
        glideHP.go("=1");
    });
  });
});

function throttle(mainFunction, delay) {
  let timerFlag = null; // Variable to keep track of the timer

  // Returning a throttled version
  return (...args) => {
    if (timerFlag === null) {
      // If there is no timer currently running
      mainFunction(...args); // Execute the main function
      timerFlag = setTimeout(() => {
        // Set a timer to clear the timerFlag after the specified delay
        timerFlag = null; // Clear the timerFlag to allow the main function to be executed again
      }, delay);
    }
  };
}
/******* SLIDERS  */

/******* lightbox */
import GLightbox from "glightbox";
const lightbox = GLightbox();

/******* paralax */
import "intersection-observer";

const paralaxObserver = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add(entry.target.dataset.paralax);
      paralaxObserver.unobserve(entry.target);
    }
  });
});

document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll(".paraX").forEach((elm) => {
    paralaxObserver.observe(elm);
  });
});

// SmoothScroll inicializace
import SmoothScroll from "smooth-scroll";
new SmoothScroll('a[href*="#"]', {
  header: "header",
  speed: 300,
  speedAsDuration: true,
});

// NodeList.prototype.forEach() polyfill
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

// clickToggleClass - BEGIN
window.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll("*[data-click-toggle-class]").forEach((elm) => {
    elm.addEventListener("click", (event) => {
      event.preventDefault();

      const classes = event.currentTarget.dataset.clickToggleClass.split("|");
      const targets = event.currentTarget.dataset.clickToggleTarget
        ? [...document.querySelectorAll(event.target.dataset.clickToggleTarget)]
        : [event.target];
      const toggledClases = targets[0].classList.contains(classes[0])
        ? {
            remove: classes[0],
            add: classes[1],
          }
        : {
            remove: classes[1],
            add: classes[0],
          };
      targets.forEach((elm) => {
        elm.classList.remove(toggledClases.remove);
        elm.classList.add(toggledClases.add);
      });
    });
  });
});
// clickToggleClass - END
 const menu__main = document.querySelector('#menu__main');
 const menu__closer = document.querySelector('#menu__closer');
 const menu__opener = document.querySelector('#menu__opener');
 document.addEventListener("DOMContentLoaded", () => {
  menu__opener.addEventListener("click", (event) => {
    menu__main.classList.add('menu__main__open');
  });
  menu__closer.addEventListener("click", (event) => {
    menu__main.classList.remove('menu__main__open');
  });
 });
// horizontal swipe
/*
document.addEventListener("DOMContentLoaded", () => {
  const container = document.querySelector(".container_swipe");
  const buttons = document.querySelectorAll(".nav-btn");
  let currentIndex = 0;
  let isDragging = false;
  let startX = 0;
  let deltaX = 0;

  function scrollToSection(index) {
      if (index < 1) index = 1;
      if (index > 13) index = 13;
      currentIndex = index - 1;
      container.style.transform = `translateX(-${currentIndex * 100}vw)`;
  }

  buttons.forEach(button => {
      button.addEventListener("click", () => {
          const target = parseInt(button.dataset.target, 10);
          scrollToSection(target);
      });
  });

  document.addEventListener("wheel", (event) => {
      if (event.deltaY > 0 && currentIndex < 12) {
          scrollToSection(currentIndex + 2);
      } else if (event.deltaY < 0 && currentIndex > 0) {
          scrollToSection(currentIndex);
      }
  });

  document.addEventListener("touchstart", (event) => {
      startX = event.touches[0].clientX;
  });

  document.addEventListener("touchend", (event) => {
      let endX = event.changedTouches[0].clientX;
      if (startX - endX > 50 && currentIndex < 12) {
          scrollToSection(currentIndex + 2);
      } else if (endX - startX > 50 && currentIndex > 0) {
          scrollToSection(currentIndex);
      }
  });

  // Dragging with mouse
  container.addEventListener("mousedown", (e) => {
      isDragging = true;
      startX = e.clientX;
      container.style.cursor = "grabbing";
  });

  container.addEventListener("mousemove", (e) => {
      if (!isDragging) return;
      deltaX = e.clientX - startX;
  });

  container.addEventListener("mouseup", () => {
      isDragging = false;
      container.style.cursor = "grab";
      
      if (deltaX < -50 && currentIndex < 12) {
          scrollToSection(currentIndex + 2);
      } else if (deltaX > 50 && currentIndex > 0) {
          scrollToSection(currentIndex);
      }
      
      deltaX = 0;
  });

  container.addEventListener("mouseleave", () => {
      isDragging = false;
      container.style.cursor = "grab";
      deltaX = 0;
  });
});
*/
document.addEventListener("DOMContentLoaded", function () {
  const scrollToTopButton = document.getElementById("scrollToTop");
  // Plynulý scroll nahoru
  if (scrollToTopButton) {
    scrollToTopButton.addEventListener("click", function () {
      window.scrollTo({ top: 0, behavior: "smooth" });
    });
  }
});
